body {
  font-family: sans-serif;
}
.chat-opener {
  z-index: 5;
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #5086e9;
  bottom: 60px;
  right: 16px;
  cursor: pointer;
  content: url(https://hugo.legal/assets/imgs/chat-icon.svg);

  padding: 15px 10px 10px 10px;
}

.chat-wrapper {
  z-index: 5;
  background-color: white;
  border: 1px solid #eee;
  width: 488px;
  height: 80%;
  position: absolute;
  right: 50px;
  bottom: 40px;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px;
}

.chat-header {
  border-bottom: 1px solid lightgray;
  padding: 10px;
  text-align: center;
}

.close-icon {
  color: #5086e9;
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.chat-body {
  display: flex;
  height: calc(100% - 98px);
  flex-direction: column;
  overflow-y: auto;
}

.message-wrapper-row {
  display: flex;
  align-items: center;
  padding: 10px;
}
#message-wrapper-decisions {
  margin-left: 35px;
}
#message-wrapper-booking {
  margin-top: -35px;
}
.booking-type {
  margin-top: -65px;
}
.message-wrapper-user {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
}
.message-wrapper-user .message {
  border-radius: 0.75rem 0.75rem 0 0.75rem;
  background-color: aliceblue;
}
.message {
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  padding: 0.75em;
  background-color: #eee;
  display: block;
  border: none;
  font-size: 0.835rem;
  line-height: 1.1875rem;
  max-width: 16.4rem;
  width: max-content;
  min-height: 0.75rem;
  word-break: break-all;
}

.message p {
  word-break: break-word;
}

.chat-footer {
  width: 100%;
  position: absolute;
  font-size: 16px;
  bottom: 0;
}

.file-drop-zone {
  flex: 1;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 0px 30px;
}

.file-drop-zone__error {
  color: red;
}

.widget-image-thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.widget-image {
  display: block;
  width: auto;
  height: 100%;
  min-width: 0;
}

.input-group-text {
  padding: 0 1rem;
}

.google-autocomplete-input {
  width: 100%;
}

#typing-indicator {
  will-change: transform;
  display: table;
  position: relative;
  animation: 2.5s bulge infinite ease-out;
}

#typing-indicator::after {
  height: 10px;
  width: 10px;
  left: -8px;
  bottom: -8px;
}

#typing-indicator span {
  height: 11px;
  width: 11px;
  float: left;
  margin: 0px 2px;
  background-color: #5086e9;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
#typing-indicator span:nth-of-type(1) {
  animation: 2s blink infinite 0.66s;
}

#typing-indicator span:nth-of-type(2) {
  animation: 2s blink infinite 1.333s;
}

#typing-indicator span:nth-of-type(3) {
  animation: 2s blink infinite 1.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
